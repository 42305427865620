import layers from "../assets/icons/layers.svg";
import user from "../assets/icons/user.svg";
import squares from "../assets/icons/squares.svg";

export const navs = [
  {
    link: "/",
    title: "Offers",
    icon: layers,
  },
  {
    link: "/my-applications",
    title: "My Applications",
    icon: user,
  },
];

export const gender = [
  {
    _id: 1,
    title: "Male",
  },
  {
    _id: 2,
    title: "Female",
  },
];

export const genderlevel = [
  {
    title: "Bac",
  },
  {
    title: "Liscence",
  },
];

export const cities = [
  { title: "Tunis" },
  { title: "Sfax" },
  { title: "Sousse" },
  { title: "Kairouan" },
  { title: "Bizerte" },
  { title: "Gabès" },
  { title: "Ariana" },
  { title: "Gafsa" },
  { title: "La Marsa" },
  { title: "Sidi Bouzid" },
  { title: "Tataouine" },
  { title: "Médenine" },
  { title: "Monastir" },
  { title: "Nabeul" },
  { title: "Ben Arous" },
  { title: "Kasserine" },
  { title: "Kef" },
  { title: "Tozeur" },
  { title: "Mahdia" },
  { title: "Siliana" },
  { title: "Bèja" },
  { title: "Kebili" },
  { title: "Jendouba" },
  { title: "Zaghouan" },
];

export const initialStepsArr = [
  {
    label: "Shipping address",
    description: "Add your delivery address",
    isCompleted: false,
  },
  {
    label: "Payment details",
    description: "Add your mode of payment",
    isCompleted: false,
  },
  {
    label: "Review your order",
    description: "Verify your order details",
    isCompleted: false,
  },
];

export const selectCities = [
  { label: "Tunis", value: "Tunis" },
  { label: "Sfax", value: "Sfax" },
  { label: "Sousse", value: "Sousse" },
  { label: "Kairouan", value: "Kairouan" },
  { label: "Bizerte", value: "Bizerte" },
  { label: "Gabès", value: "Gabès" },
  { label: "Ariana", value: "Ariana" },
  { label: "Gafsa", value: "Gafsa" },
  { label: "Monastir", value: "Monastir" },
  { label: "Ben Arous", value: "Ben Arous" },
  { label: "Kasserine", value: "Kasserine" },
  { label: "Tataouine", value: "Tataouine" },
  { label: "Medenine", value: "Medenine" },
  { label: "Bèja", value: "Bèja" },
  { label: "Jendouba", value: "Jendouba" },
  { label: "Zaghouan", value: "Zaghouan" },
  { label: "Nabeul", value: "Nabeul" },
  { label: "Tozeur", value: "Tozeur" },
  { label: "Siliana", value: "Siliana" },
  { label: "Mahdia", value: "Mahdia" },
  { label: "Sidi Bouzid", value: "Sidi Bouzid" },
  { label: "Manouba", value: "Manouba" },
  { label: "Kef", value: "Kef" },
  { label: "Kebili", value: "Kebili" },
];
