import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Filter from "../../components/Filter";
import Header from "../Header";
import SideBar from "../SideBar";

import "./mainLayout.scss";

type MainLayoutProps = {
  children: React.ReactNode;
};

const MainLayout = ({ children }: MainLayoutProps) => {
  const { isSmall } = useSelector((state: any) => state.SideBar);
  const { offres } = useSelector((state: any) => state.Offre);
  const { pathname } = useLocation();

  return (
    <main className="main_layout">
      <SideBar />

      <div className={`content ${isSmall ? "small" : ""}`}>
        <Header />

        <div className="content-children">{children}</div>
      </div>
    </main>
  );
};

export default MainLayout;
